import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Home } from './pages/home'
import { Music } from './pages/music';
import { Games } from './pages/games';
import MakotoImage from './assets/Makoto2.png';
import HomeButtonImage from './assets/home.png';
import GamesButtonImage from './assets/games.png';
import MusicButtonImage from './assets/music.png';
import AnimeButtonImage from './assets/Anime.png';

function App() {
  return (
    <>
      <div className='ploot-col ploot-main' style={{alignItems: 'stretch'}}>
        <div className='ploot-row' style={{justifyContent: 'center', marginTop: '30px'}}>
          <img src={MakotoImage} alt='Plootopia' />
        </div>
        <div className='ploot-col' style={{alignItems: 'stretch', justifyContent: 'stretch', flexGrow: '1'}}>
        <div className='ploot-row ploot-f-start' style={{alignItems: 'flex-start', flexGrow: '1'}}>
          <div className='ploot-col' style = {{alignItems: 'flex-start'}}>
            <Link to={"/"}>
              <button className='ploot-menu-button'>
                  <img src = {HomeButtonImage}/>
              </button>
            </Link>
            <Link to={"/music"}>
              <button className='ploot-menu-button'>
                  <img src = {MusicButtonImage}/> 
              </button>
            </Link>
            <Link to={"/games"}>
              <button className='ploot-menu-button'>
                  <img src = {GamesButtonImage}/>
              </button>
            </Link>
            <Link to={"/anime"}>
              <button className='ploot-menu-button'>
                  <img src = {AnimeButtonImage}/>
              </button>
            </Link>
          </div>
          <div className='ploot-col' style={{flexGrow: '1', height: '100%'}}>
          <Routes>
            <Route path="/" Component={Home}></Route>
            <Route path="/music" Component={Music}></Route>
            <Route path="/games" Component={Games}></Route>
          </Routes>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default App;
