import React, { ReactElement } from "react";
import MakotoImage from '../assets/welcome.png';
import Duck from '../assets/duck.png';


export function Home(): ReactElement{
    return <div className="ploot-col" style={{height: '100%', justifyContent: 'flex-end'}}>
        <img src={Duck} style = {{height: '100px', width: '100px'}} alt='Plootopia' />
        <img src={MakotoImage} alt='Plootopia' />
        <div className="ploot-row" style={{marginTop: '-30px'}}>
            <a href="http://www.p5generator.com/">
                Persona dialogue image courtesy of p5 generator.
            </a>
        </div>
    </div>
}